
@font-face 
  font-family: 'Poppins'
  font-style: normal
  font-display: swap
  font-weight: 400
  src: url(../../fonts/Poppins/Poppins-Regular.ttf) format('truetype')
  unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF
p,div,span,sub,sup,h1,h2,h3,h4,h5,h6
    font-family: "Poppins"!important
html 
  scroll-behavior: smooth

.MuiCheckbox-root
    padding: 0!important
    
.Mui-disabled 
    -webkit-text-fill-color: #00000099!important
.MuiCard-root .MuiGrid-item 
    padding: 7px!important

// .MuiBackdrop-root
//     background-color: rgb(0 0 0 / 55%)!important
//     webkit-backdrop-filter: saturate(180%) blur(10px)
//     backdrop-filter: saturate(180%) blur(10px)
.MuiCard-root
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1)!important
    border-radius: 10px!important
    margin: 15px!important
hr
    border: 1px solid #F3F3F3

.MuiPagination-ul
    margin: 0
    display: flex
    padding: 0
    flex-wrap: inherit
    list-style: none
    align-items: center
.data-table
    svg
        cursor: pointer
    .MuiTableCell-root
        padding: 4px 4px!important
    th,td
        font-size: 13px!important
        min-width: 8rem !important
.containerMain
    max-width: 500px
    min-height: 100vh
    margin: 0px auto
    border: 1px solid #dddddd
    border-radius: 5px
    background: #F5F5F5
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)
    padding-top: 0px
    overflow: auto
    height: 100vh
.dialogBottom
    .MuiDialog-paperScrollPaper
        border-radius: 15px 15px 0px 0px
        bottom: 0px
        margin: 0px
        position: absolute
        overflow: hidden
.dialogContentMain
    max-width: 500px
    padding: 30px 30px 8px
.dialogContent
    max-width: 500px
    padding: 5px 15px 10px 30px
    margin-top: 0px!important
.centerContainer
    position: relative
.centerItem
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
.locationSelect
    cursor: pointer
    &:hover
        background: #eeeeee
        color:#E06358
.pointerHover
    color:#E06358
    cursor: pointer
.MuiLinearProgress-root
    height: 18px!important
    border-radius: 5px
.rectPink 
    background-image: url('../../images/pinkRect.png')
    width: 100%
    min-height: 100vh
    background-repeat: no-repeat
    background-position-y: bottom
    background-size: contain
    padding-bottom: 10px
.cusTab1
    width: 50%
    padding: 10px 15px
    display: flex
    align-items: center
    justify-content: space-around
